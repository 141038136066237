<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Body fat percentage</div>
			<div class="bmi-h5-cotent-text">
				<div>Body fat percentage is the mass of fat as apercentage of the total body mass.
				</div>
				<div>Obesity is not measured by weight, but by body fatpercentage.</div>
				<div>Body fat plays an important role in maintainingbody temperature, reducing the impact of force onyour body, keeping skin moisturized, maintaininghormone secretion, and other functions.</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
</style>