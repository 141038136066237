





























































































































































































































@import "../../assets/css/mixinmap";
@import "../../variable.scss";
.homelogo {
  @include handleWidth($homelogo);
}
.homeheader {
  @include handleMaxDisplay($homeheader);
}
.homeheader1 {
  @include handleMaxDisplay($homeheader1);
}
.home-header {
  display: flex;
  align-items:center;
  z-index: 10;
  height: 80PX;
  font-size: 22PX;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // background-color: #fff;
  font-weight: 600;
  color: #505050;
  box-sizing: border-box;
  @include handlePadding($homepadding);
  :hover {
    color: #e46229;
  }
}
.bonner {
  @include handleWidth($bonner);
}
.home-menu {
  cursor: pointer;
  padding: 0 20PX;
  height: 65PX;
  line-height: 65PX;
  font-size: 22PX;
  color: #FFFFFF;
}
.home-menu-scroll {
  cursor: pointer;
  padding: 0 20PX;
  height: 65PX;
  line-height: 65PX;
  font-size: 22PX;
  color: #000;
}
.header-bgimg{
  background-image: url("../../assets/377047547ef464ff12cce3d9f3304d1.jpg");
  // background-image: url("../../assets/Shape8.png");
  background-position: 50% 0PX;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.header-bgimg-float{
  background-image: url("../../assets/Shape8-1.png");
}
.home-banner {
  // height: 450PX;
  // background-image: url("../../assets/377047547ef464ff12cce3d9f3304d1.jpg");
  // background-position: 50% 32.5PX;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
}
.home-banner-float {
  padding-top: 12vw;

  // background-image: url("../../assets/Shape8.png");
}

.home-banner-2 {
  background-image: url("../../assets/oc_bgi.jpg");
  background-position: 50% 32.5PX;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.home-banner-2-float {
  // background-image: url("../../assets/falsj.png");
  background-image: url("../../assets/Shape8-2.png");
  padding-bottom: 72PX;
}
.culture {
  @include handleWidth($culture);
}
.culture-block {
  padding: 35PX 5PX;
  width: 45%;
  @include handleWidth($culture_block_width);
  background-image: url("../../assets/nlwdlam-1.png");
  text-align: center;
  height: 300PX;
  margin: 5px 0;
  img {
    margin: 10PX 0;
    height: 30PX;
    @include handleHeight($culture_img_height);
  }
}
.culture-content {
  font-size: 15PX;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // color: rgba(15, 48, 104, 1);
  color: #FFFFFF;
  line-height: 25PX;
}
.parters-left {
  color: #eb6100;
}
.culture-title {
  margin-bottom: 20PX;
  color: #eb6100;
  font-size: 22PX;
  font-weight: 600;
}
.features-body {
  width: -webkit-fill-available;
  @include handlePadding($features_body_padding);
}
.partners {
  width: 100%;

  // height: 400PX;
  background-color: #fff;
  &-left {
    width: 300PX;
    text-align: center;
    @include handleWidth($partners_left_width);

    p {
      @include handleFontSize($partners_left_font_size);
      color: #000;
      font-weight: bold;
    }
  }
  &-right {
    flex: 2;
    height: 100%;
    padding: 0 30PX;
  }
}
.partners-img {
  @include handleHeight($partners_img_height);
}
.partners2-img {
  @include handleHeight($partners2_img_height);
}
.partners2 {
  margin-top: 40PX;
}
.partners2 .partners-left {
  background-size: 15PX 100%;
}
.partners2 .partners-right {
  text-align: center;
  // background: url("../../assets/logo.png") no-repeat center center;
  height: 100%;
}
.bann-p {
  width: 600PX;
  @include handleLineHeight($bannp_lineheight);
  font-size: 18PX;
  color: #000;
  margin-bottom: 20PX;
  p {
    color: #000;
    @include handleFontSize($home_footer_font_size);
    margin: 0;
  }
}
.down-img {
  cursor: pointer;
  box-shadow: 0PX 0PX 8PX 0PX rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 15PX;
  border-bottom-left-radius: 15PX;
}
.banner-top {
  padding-top: 60PX;
}
.banner-title {
  font-size: 70PX;
  color: rgb(242, 99, 36);
}
.trusted {
  text-align: center;
  margin-bottom: 50PX;
  span {
    color: #fff;
    font-weight: bold;
    @include handleFontSize($trusted_font_size);
  }
}

.home-download {
  background-color: rgb(218, 185, 107);
  width: 210PX;
  height: 63PX;
  line-height: 63PX;
  margin: 10PX 0 10PX 0;
  text-align: center;
  text-shadow: 0 1PX 1PX rgba(21, 21, 22, 0.25);
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  span {
    color: #fff;
    font-size: 20PX;
  }
}
.home-footer-img {
  @include handleWidth($home_footer_img_width);
}

.home-into {
  color: #fff;
  padding: 20PX;
  @include handleWidth($home_into_width);
  span {
    font-size: 17PX;
  }
}
.check-row {
  padding-top: 56PX;
  padding-bottom: 86PX;
}
.check-download {
  span {
    font-size: 20PX;
  }
}
.features {
  // background-image: url("../../assets/pattern_bg.png");
  padding-bottom: 80PX;
  // color: #000;
  // background-color: #0f439c;
  &-row {
    color: #fff;
    font-size: 18PX;
    line-height: 45PX;
    padding: 15PX;
  }
  &-body {
  }
  &-item {
    padding: 60PX 10PX 30PX 10PX;
    text-align: center;
    border-bottom: 20PX solid #cecece;
    border-top: 20PX solid #cecece;
    border-right: 10PX solid #cecece;
    border-left: 10PX solid #cecece;
    width: 285PX;
    margin-top: 40PX;
    margin-bottom: 40PX;
    background-color: #fff;
  }
  &-smalltitle {
    color: rgb(242, 99, 36);
    font-size: 24PX;
    margin-top: 20PX;
    margin-bottom: 10PX;
    height: 48PX;
    line-height: 48PX;
  }
  &-content {
    line-height: 36PX;
    font-size: 18PX;
  }
  &-form {
    &-icon-list {
      padding: 40PX 20PX;
      div {
        border-radius: 50%;
        height: 40PX;
        width: 40PX;
        cursor: pointer;
      }
    }
    &-item {
      margin-bottom: 10PX;
      padding: 0 18PX;
      border-bottom: 3PX solid #fff !important;
      &-sumbit {
        background-color: #275267;
        width: 50%;
        border-bottom: 3PX solid #fff;
        -webkit-appearance: button;
        cursor: pointer;
        font-size: 14PX;
      }
      input {
        background: transparent;
        border-radius: 0PX;
        border: hidden !important;
        color: #fff;
        -webkit-writing-mode: horizontal-tb !important;
        outline: none;
        height: 63PX;
        line-height: 63PX;
        font-size: 18PX;
        width: 100%;
      }
      textarea {
        background: transparent;
        border-radius: 0PX;
        border: hidden !important;
        color: #fff;
        -webkit-writing-mode: horizontal-tb !important;
        outline: none;
        line-height: 63PX;
        font-size: 18PX;
        width: 100%;
      }
    }
  }
}

.features-title {
  position: relative;
  font-size: 39PX;
  font-weight: bold;
  color: #000;
  font: 500 40PX/150PX "Fira Sans", sans-serif;
  @include handleFontSize($features_title_font_size);
  margin: 0;
}
.features-bottom {
  position: absolute;
  width: 100%;
  bottom: 45PX;
  color: #000;
  font: 200 35PX/150PX "Fira Sans", sans-serif;
  line-height: 10PX;
}
.checktop {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%,-0);
}
.home-footer {
  position: relative;
  padding: 40PX 0;
  background: #333333;
  &-div {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      margin-bottom: 20PX;
    }
    p {
      color: #fff;
      @include handleFontSize($home_footer_font_size);

      margin-bottom: 10PX;
    }
  }
}
