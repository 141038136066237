<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Basal metabolic rate</div>
			<div class="bmi-h5-cotent-text">
				<div>Basal metabolic rate refers to the total energyconsumed by a person while awake and in arelaxed state, without the effects of exercise,food, anxiety, or changes in temperature of thesurroundings.</div>
				<div>Basal metabolic rate is related to muscle mass,where a higher muscle mass gives a highermetabolic rate. Generally, basal metabolic ratepeaks at an age of 16-18 years, followed by agentle decline until around an age of 40 years,after which it sharply declines.</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
	.van-row{
		text-align: center;
	}
	.bmi-h5-cotent-form{
		width: 90%;
		margin: 20px auto;
	}
	.van-row{
		margin-bottom: 2px;
		font-size: 14px;
		font-weight: 200;
	}
	.van-col{
		background: #384855;
		padding: 5px 0;
	}
</style>