html,
body {
  max-width: 540px;
  margin: 0 auto;
  background-color: #f7f8fa;
  width: 100%;
  height: 100%;
}
#app {
  font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.error_tip {
  width: 80% !important;
}
.error_tip .van-toast__text {
  font-size: 18px;
}
.van-icon-search {
  color: #f78e1e !important;
}
