
$home_into_width: (
  null: 0,
  300PX: 350PX,
  768PX: 560PX,
  1024PX: 680PX,
  1280PX: 720PX
);
$culture: (
  null: 0,
  300PX: 375PX,
  768PX: 600PX,
  1024PX: 1000PX,
  1280PX: 1280PX
);

$culture_img_height: (
  null: 0,
  300PX: 25PX,
  768PX: 40PX,
  1024PX: 50PX,
  1280PX: 50PX
);
$partners_left_width: (
  null: 0,
  300PX: 80PX,
  414PX: 100PX,
  500PX: 170PX,
  768PX: 200PX,
  1024PX: 220PX,
  1280PX: 230PX
);
$partners_img_height:(
  null: 0,
  300PX: 80PX,
  768PX: 200PX,
  1024PX: 240PX,
  1280PX: 300PX
);
$partners2_img_height:(
  null: 0,
  300PX: 60PX,
  768PX: 150PX,
  1024PX: 180PX,
  1280PX: 200PX
  );
$partners_left_font_size: (
  null: 0,
  300PX: 12px,
  768PX: 14px,
  1024PX: 16px,
  1280PX: 20px
);

$features_body_padding: (
  null: 0,
  300PX:0 20PX,
  768PX: 30PX,
  1024PX: 30PX,
  1280PX: 0 15%
);

$culture_block_width: (
  null: 0,
  300PX: 170PX,
  414PX: 170PX,
  500PX: 170PX,
  768PX: 200PX,
  1024PX: 220PX,
  1280PX: 230PX
);
$home_footer_font_size: (
  null: 0,
  300PX: 12PX,
  414PX: 14PX,
  500PX: 16PX,
  768PX: 16PX,
  1024PX: 16PX,
  1280PX: 16PX
);
$bannp_lineheight: (
  null: 0,
  300PX: 23PX,
  414PX: 23PX,
  500PX: 25PX,
  768PX: 26PX,
  1024PX: 27PX,
  1280PX: 28PX
);

$home_footer_img_width: (
  null: 0,
  300PX: 200PX,
  414PX: 200PX,
  500PX: 220PX,
  768PX: 250PX,
  1024PX: 270PX,
  1280PX: 300PX
);

$homelogo: (
  null: 0,
  300PX: 180PX,
  768PX: 220PX,
  1024PX: 250PX,
  1280PX: 300PX
);
$homeheader: (
  null: none,
  375PX: none,
  768PX: flex
);

$homeheader1: (
  null: none,
  375PX: flex,
  768PX: none,
);

$homepadding:(
  null: 0,
  375PX: 20PX,
  768PX: 0 10%,
  1024PX: 0 15%,
  );
  
$bonner: (
  null: 0,
  300PX: 80%,
  768PX: 60%,
  1024PX: 70%,
  1280PX: 81%
);
  
$trusted_font_size: (
  null: 0,
  300PX: 37PX,
  768PX: 50PX,
  1024PX: 57PX,
  1280PX: 57PX
);

$features_title_font_size: (
  null: 0,
  300PX: 25PX,
  768PX: 28PX,
  1024PX: 32PX,
  1280PX: 39PX
);
