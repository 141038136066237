
@mixin handleWidth($widthMap) {
    @each $key, $widthItem in $widthMap {
      @if $key == null {
        width: $widthItem;
      } @else {
        @media screen and (min-width: $key) {
          width: $widthItem;
        }
      }
    }
  }
  
@mixin handleMinWidth($widthMap) {
    @each $key, $widthItem in $widthMap {
      @if $key == null {
        min-width: $widthItem;
      } @else {
        @media screen and (min-width: $key) {
          min-width: $widthItem;
        }
      }
    }
  }

  @mixin handleFontSize($fontSizeMap) {
    @each $key, $sizeItem in $fontSizeMap {
      @if $key == null {
        font-size: $sizeItem;
      } @else {
        @media screen and (min-width: $key) {
          font-size: $sizeItem;
        }
      }
    }
  }
  @mixin handlePadding($paddingMap) {
    @each $key, $paddingItem in $paddingMap {
      @if $key == null {
        padding: $paddingItem;
      } @else {
        @media screen and (min-width: $key) {
           padding: $paddingItem;
        }
      }
    }
  }
    
  @mixin handleLineHeight($paddingMap) {
    @each $key, $paddingItem in $paddingMap {
      @if $key == null {
        line-height: $paddingItem;
      } @else {
        @media screen and (min-width: $key) {
          line-height: $paddingItem;
        }
      }
    }
  }
  @mixin handleHeight($paddingMap) {
    @each $key, $paddingItem in $paddingMap {
      @if $key == null {
        height: $paddingItem;
      } @else {
        @media screen and (min-width: $key) {
          height: $paddingItem;
        }
      }
    }
  }
    
  @mixin handleMinDisplay($displayMap) {
    @each $key, $displayItem in $displayMap {
      @if $key == null {
        display: $displayItem;
      } @else {
        @media screen and (max-width: $key) {
           display: $displayItem;
        }
      }
    }
  }
      
@mixin handleMaxDisplay($displayMap) {
  @each $key, $displayItem in $displayMap {
    @if $key == null {
      display: $displayItem;
    } @else {
      @media screen and (min-width: $key) {
         display: $displayItem;
      }
    }
  }
}