@charset "UTF-8";

.fy {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
}

.fy-reverse-list {
	display: flex;
	display: -webkit-flex;
	flex-direction: column-reverse;
	align-items: center;
}

.fy-reverse-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}

.fy-reverse-fulllist {
	display: flex;
	display: -webkit-flex;
	flex-direction: column-reverse;
	align-items: center;
}

.fy-reverse-between-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.fy-reverse-around-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.fy-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fy-list {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	align-items: center;
}

.fy-fulllist {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	align-items: stretch;
}

.fy-between-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.fy-around-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.fx {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
}

.fx-reverse {
	display: flex;
	display: -webkit-flex;
	flex-direction: row-reverse;
}

.fx-reverse-list {
	display: flex;
	display: -webkit-flex;
	flex-direction: row-reverse;
	align-items: center;
}

.fx-reverse-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.fx-reverse-fulllist {
	display: flex;
	display: -webkit-flex;
	flex-direction: row-reverse;
	align-items: center;
}

.fx-reverse-between-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.fx-reverse-around-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.fx-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.fx-list {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	align-items: center;
}

.fx-fulllist {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	align-items: stretch;
}

.fx-between-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.fx-around-box {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.f-start {
	align-self: flex-start;
}

.f-end {
	align-self: flex-end;
}

.f-center {
	align-self: center;
}

.f-stretch {
	align-self: stretch;
}

.f-grow {
	flex-grow: 1;
}

.f-shrink {
	flex-shrink: 1;
}

.f-noshrink {
	flex-shrink: 0;
}

.f-wrap {
	flex-wrap: wrap;
}

.f-r0 {
	row-gap: 0px;
}

.f-c0 {
	column-gap: 0px;
}

.f-r1 {
	row-gap: 10px;
}

.f-c1 {
	column-gap: 10px;
}

.f-r2 {
	row-gap: 20px;
}

.f-c2 {
	column-gap: 20px;
}

.f-r3 {
	row-gap: 30px;
}

.f-c3 {
	column-gap: 30px;
}

.f-r4 {
	row-gap: 40px;
}

.f-c4 {
	column-gap: 40px;
}

.f-r5 {
	row-gap: 50px;
}

.f-c5 {
	column-gap: 50px;
}

.f-r6 {
	row-gap: 60px;
}

.f-c6 {
	column-gap: 60px;
}

.f-r7 {
	row-gap: 70px;
}

.f-c7 {
	column-gap: 70px;
}

.f-r8 {
	row-gap: 80px;
}

.f-c8 {
	column-gap: 80px;
}

.f-r9 {
	row-gap: 90px;
}

.f-c9 {
	column-gap: 90px;
}

.f-r10 {
	row-gap: 100px;
}

.f-c10 {
	column-gap: 100px;
}

.fll {
	float: left;
}

.flr {
	float: right;
}

.fclear {
	clear: both;
}

.w1 {
	width: 10%;
}

.w2 {
	width: 20%;
}

.w3 {
	width: 30%;
}

.w4 {
	width: 40%;
}

.w5 {
	width: 50%;
}

.w6 {
	width: 60%;
}

.w7 {
	width: 70%;
}

.w8 {
	width: 80%;
}

.w9 {
	width: 90%;
}

.w10 {
	width: 100%;
}

.h1 {
	height: 10%;
}

.h2 {
	height: 20%;
}

.h3 {
	height: 30%;
}

.h4 {
	height: 40%;
}

.h5 {
	height: 50%;
}

.h6 {
	height: 60%;
}

.h7 {
	height: 70%;
}

.h8 {
	height: 80%;
}

.h9 {
	height: 90%;
}

.h10 {
	height: 100%;
}

.p0 {
	padding: 0px;
}

.px0 {
	padding-left: 0px;
	padding-right: 0px;
}

.py0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.pl0 {
	padding-left: 0px;
}

.pr0 {
	padding-right: 0px;
}

.pt0 {
	padding-top: 0px;
}

.pb0 {
	padding-bottom: 0px;
}

.p1 {
	padding: 5px;
}

.px1 {
	padding-left: 5px;
	padding-right: 5px;
}

.py1 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.pl1 {
	padding-left: 5px;
}

.pr1 {
	padding-right: 5px;
}

.pt1 {
	padding-top: 5px;
}

.pb1 {
	padding-bottom: 5px;
}

.p2 {
	padding: 10px;
}

.px2 {
	padding-left: 10px;
	padding-right: 10px;
}

.py2 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.pl2 {
	padding-left: 10px;
}

.pr2 {
	padding-right: 10px;
}

.pt2 {
	padding-top: 10px;
}

.pb2 {
	padding-bottom: 10px;
}

.p3 {
	padding: 15px;
}

.px3 {
	padding-left: 15px;
	padding-right: 15px;
}

.py3 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.pl3 {
	padding-left: 15px;
}

.pr3 {
	padding-right: 15px;
}

.pt3 {
	padding-top: 15px;
}

.pb3 {
	padding-bottom: 15px;
}

.p4 {
	padding: 20px;
}

.px4 {
	padding-left: 20px;
	padding-right: 20px;
}

.py4 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.pl4 {
	padding-left: 20px;
}

.pr4 {
	padding-right: 20px;
}

.pt4 {
	padding-top: 20px;
}

.pb4 {
	padding-bottom: 20px;
}

.p5 {
	padding: 25px;
}

.px5 {
	padding-left: 25px;
	padding-right: 25px;
}

.py5 {
	padding-top: 25px;
	padding-bottom: 25px;
}

.pl5 {
	padding-left: 25px;
}

.pr5 {
	padding-right: 25px;
}

.pt5 {
	padding-top: 25px;
}

.pb5 {
	padding-bottom: 25px;
}

.p6 {
	padding: 30px;
}

.px6 {
	padding-left: 30px;
	padding-right: 30px;
}

.py6 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.pl6 {
	padding-left: 30px;
}

.pr6 {
	padding-right: 30px;
}

.pt6 {
	padding-top: 30px;
}

.pb6 {
	padding-bottom: 30px;
}

.p7 {
	padding: 35px;
}

.px7 {
	padding-left: 35px;
	padding-right: 35px;
}

.py7 {
	padding-top: 35px;
	padding-bottom: 35px;
}

.pl7 {
	padding-left: 35px;
}

.pr7 {
	padding-right: 35px;
}

.pt7 {
	padding-top: 35px;
}

.pb7 {
	padding-bottom: 35px;
}

.p8 {
	padding: 40px;
}

.px8 {
	padding-left: 40px;
	padding-right: 40px;
}

.py8 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.pl8 {
	padding-left: 40px;
}

.pr8 {
	padding-right: 40px;
}

.pt8 {
	padding-top: 40px;
}

.pb8 {
	padding-bottom: 40px;
}

.p9 {
	padding: 45px;
}

.px9 {
	padding-left: 45px;
	padding-right: 45px;
}

.py9 {
	padding-top: 45px;
	padding-bottom: 45px;
}

.pl9 {
	padding-left: 45px;
}

.pr9 {
	padding-right: 45px;
}

.pt9 {
	padding-top: 45px;
}

.pb9 {
	padding-bottom: 45px;
}

.p10 {
	padding: 50px;
}

.px10 {
	padding-left: 50px;
	padding-right: 50px;
}

.py10 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.pl10 {
	padding-left: 50px;
}

.pr10 {
	padding-right: 50px;
}

.pt10 {
	padding-top: 50px;
}

.pb10 {
	padding-bottom: 50px;
}

.p0e {
	padding: 0em;
}

.px0e {
	padding-left: 0em;
	padding-right: 0em;
}

.py0e {
	padding-top: 0em;
	padding-bottom: 0em;
}

.p1e {
	padding: 0.25em;
}

.px1e {
	padding-left: 0.25em;
	padding-right: 0.25em;
}

.py1e {
	padding-top: 0.25em;
	padding-bottom: 0.25em;
}

.p2e {
	padding: 0.5em;
}

.px2e {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.py2e {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.p3e {
	padding: 0.75em;
}

.px3e {
	padding-left: 0.75em;
	padding-right: 0.75em;
}

.py3e {
	padding-top: 0.75em;
	padding-bottom: 0.75em;
}

.p4e {
	padding: 1em;
}

.px4e {
	padding-left: 1em;
	padding-right: 1em;
}

.py4e {
	padding-top: 1em;
	padding-bottom: 1em;
}

.p5e {
	padding: 1.25em;
}

.px5e {
	padding-left: 1.25em;
	padding-right: 1.25em;
}

.py5e {
	padding-top: 1.25em;
	padding-bottom: 1.25em;
}

.p6e {
	padding: 1.5em;
}

.px6e {
	padding-left: 1.5em;
	padding-right: 1.5em;
}

.py6e {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
}

.p7e {
	padding: 1.75em;
}

.px7e {
	padding-left: 1.75em;
	padding-right: 1.75em;
}

.py7e {
	padding-top: 1.75em;
	padding-bottom: 1.75em;
}

.p8e {
	padding: 2em;
}

.px8e {
	padding-left: 2em;
	padding-right: 2em;
}

.py8e {
	padding-top: 2em;
	padding-bottom: 2em;
}

.p9e {
	padding: 2.25em;
}

.px9e {
	padding-left: 2.25em;
	padding-right: 2.25em;
}

.py9e {
	padding-top: 2.25em;
	padding-bottom: 2.25em;
}

.p10e {
	padding: 2.5em;
}

.px10e {
	padding-left: 2.5em;
	padding-right: 2.5em;
}

.py10e {
	padding-top: 2.5em;
	padding-bottom: 2.5em;
}

.m0 {
	margin: 0px;
}

.mx0 {
	margin-left: 0px;
	margin-right: 0px;
}

.my0 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.ml0 {
	margin-left: 0px;
}

.mr0 {
	margin-right: 0px;
}

.mt0 {
	margin-top: 0px;
}

.mb0 {
	margin-bottom: 0px;
}

.m1 {
	margin: 5px;
}

.mx1 {
	margin-left: 5px;
	margin-right: 5px;
}

.my1 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.ml1 {
	margin-left: 5px;
}

.mr1 {
	margin-right: 5px;
}

.mt1 {
	margin-top: 5px;
}

.mb1 {
	margin-bottom: 5px;
}

.m2 {
	margin: 10px;
}

.mx2 {
	margin-left: 10px;
	margin-right: 10px;
}

.my2 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.ml2 {
	margin-left: 10px;
}

.mr2 {
	margin-right: 10px;
}

.mt2 {
	margin-top: 10px;
}

.mb2 {
	margin-bottom: 10px;
}

.m3 {
	margin: 15px;
}

.mx3 {
	margin-left: 15px;
	margin-right: 15px;
}

.my3 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.ml3 {
	margin-left: 15px;
}

.mr3 {
	margin-right: 15px;
}

.mt3 {
	margin-top: 15px;
}

.mb3 {
	margin-bottom: 15px;
}

.m4 {
	margin: 20px;
}

.mx4 {
	margin-left: 20px;
	margin-right: 20px;
}

.my4 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.ml4 {
	margin-left: 20px;
}

.mr4 {
	margin-right: 20px;
}

.mt4 {
	margin-top: 20px;
}

.mb4 {
	margin-bottom: 20px;
}

.m5 {
	margin: 25px;
}

.mx5 {
	margin-left: 25px;
	margin-right: 25px;
}

.my5 {
	margin-top: 25px;
	margin-bottom: 25px;
}

.ml5 {
	margin-left: 25px;
}

.mr5 {
	margin-right: 25px;
}

.mt5 {
	margin-top: 25px;
}

.mb5 {
	margin-bottom: 25px;
}

.m6 {
	margin: 30px;
}

.mx6 {
	margin-left: 30px;
	margin-right: 30px;
}

.my6 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.ml6 {
	margin-left: 30px;
}

.mr6 {
	margin-right: 30px;
}

.mt6 {
	margin-top: 30px;
}

.mb6 {
	margin-bottom: 30px;
}

.m7 {
	margin: 35px;
}

.mx7 {
	margin-left: 35px;
	margin-right: 35px;
}

.my7 {
	margin-top: 35px;
	margin-bottom: 35px;
}

.ml7 {
	margin-left: 35px;
}

.mr7 {
	margin-right: 35px;
}

.mt7 {
	margin-top: 35px;
}

.mb7 {
	margin-bottom: 35px;
}

.m8 {
	margin: 40px;
}

.mx8 {
	margin-left: 40px;
	margin-right: 40px;
}

.my8 {
	margin-top: 40px;
	margin-bottom: 40px;
}

.ml8 {
	margin-left: 40px;
}

.mr8 {
	margin-right: 40px;
}

.mt8 {
	margin-top: 40px;
}

.mb8 {
	margin-bottom: 40px;
}

.m9 {
	margin: 45px;
}

.mx9 {
	margin-left: 45px;
	margin-right: 45px;
}

.my9 {
	margin-top: 45px;
	margin-bottom: 45px;
}

.ml9 {
	margin-left: 45px;
}

.mr9 {
	margin-right: 45px;
}

.mt9 {
	margin-top: 45px;
}

.mb9 {
	margin-bottom: 45px;
}

.m10 {
	margin: 50px;
}

.mx10 {
	margin-left: 50px;
	margin-right: 50px;
}

.my10 {
	margin-top: 50px;
	margin-bottom: 50px;
}

.ml10 {
	margin-left: 50px;
}

.mr10 {
	margin-right: 50px;
}

.mt10 {
	margin-top: 50px;
}

.mb10 {
	margin-bottom: 50px;
}

.m0e {
	margin: 0em;
}

.mx0e {
	margin-left: 0em;
	margin-right: 0em;
}

.my0e {
	margin-top: 0em;
	margin-bottom: 0em;
}

.m1e {
	margin: 0.25em;
}

.mx1e {
	margin-left: 0.25em;
	margin-right: 0.25em;
}

.my1e {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.m2e {
	margin: 0.5em;
}

.mx2e {
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.my2e {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.m3e {
	margin: 0.75em;
}

.mx3e {
	margin-left: 0.75em;
	margin-right: 0.75em;
}

.my3e {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.m4e {
	margin: 1em;
}

.mx4e {
	margin-left: 1em;
	margin-right: 1em;
}

.my4e {
	margin-top: 1em;
	margin-bottom: 1em;
}

.m5e {
	margin: 1.25em;
}

.mx5e {
	margin-left: 1.25em;
	margin-right: 1.25em;
}

.my5e {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
}

.m6e {
	margin: 1.5em;
}

.mx6e {
	margin-left: 1.5em;
	margin-right: 1.5em;
}

.my6e {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.m7e {
	margin: 1.75em;
}

.mx7e {
	margin-left: 1.75em;
	margin-right: 1.75em;
}

.my7e {
	margin-top: 1.75em;
	margin-bottom: 1.75em;
}

.m8e {
	margin: 2em;
}

.mx8e {
	margin-left: 2em;
	margin-right: 2em;
}

.my8e {
	margin-top: 2em;
	margin-bottom: 2em;
}

.m9e {
	margin: 2.25em;
}

.mx9e {
	margin-left: 2.25em;
	margin-right: 2.25em;
}

.my9e {
	margin-top: 2.25em;
	margin-bottom: 2.25em;
}

.m10e {
	margin: 2.5em;
}

.mx10e {
	margin-left: 2.5em;
	margin-right: 2.5em;
}

.my10e {
	margin-top: 2.5em;
	margin-bottom: 2.5em;
}

/** 文本部分,给出文本对齐,文本默认设置的导出类
  */
.font-ssm {
	font-size: 6px;
}

.font-sm {
	font-size: 10px;
}

.font-s {
	font-size: 12px;
}

.font-m {
	font-size: 14px;
}

.font-l {
	font-size: 16px;
}

.font-xl {
	font-size: 18px;
}

.font-xxl {
	font-size: 22px;
}

.font-bold {
	font-weight: bold;
}

.t-center {
	text-align: center;
}

.t-start {
	text-align: start;
}

.t-end {
	text-align: end;
}

.lh0 {
	line-height: 1;
}

.lh1 {
	line-height: 1.1;
}

.lh2 {
	line-height: 1.2;
}

.lh3 {
	line-height: 1.3;
}

.lh4 {
	line-height: 1.4;
}

.lh5 {
	line-height: 1.5;
}

.lh6 {
	line-height: 1.6;
}

.lh7 {
	line-height: 1.7;
}

.lh8 {
	line-height: 1.8;
}

.lh9 {
	line-height: 1.9;
}

.lh10 {
	line-height: 2;
}

.anchor {
	position: relative;
}

.bdbox {
	box-sizing: border-box;
}

.none {
	display: none;
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.inblock {
	display: inline-block;
}

.pointer {
	cursor: pointer;
}

/* 主题色部分 */
.orange {
	color: #f15e25;
}

.orange-bg {
	background-color: #f15e25;
}

.orange-bd {
	border-color: #f15e25;
}

.gray {
	color: #676867;
}

.gray-bg {
	background-color: #676867;
}

.gray-bd {
	border-color: #676867;
}
