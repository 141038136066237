<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Visceral fat level</div>
			<div class="bmi-h5-cotent-text">
				<div>Visceral fat is body fat that is stored within the abdominal cavity. Generally, a visceral fat rating of10 is equivalent to that of an area of 100 cm2，</div>
				<div>High levels of visceral fat may lead to highcholesterol, hardening and blocking of thearteries, hypertension, heart attack, stroke,diabetes, and fatty liver disease.</div>
			</div>
			<div class="bmi-h5-cotent-form">
				<van-row>
				  <van-col span="24">Visceral fat determination</van-col>
				</van-row>
				<van-row type="flex" justify="space-between">
				  <van-col span="6">Level 1-4.5</van-col>
				  <van-col span="6">Level 5-9.55</van-col>
				  <van-col span="6">Level 10-14.5</van-col>
				  <van-col span="6">Level 15</van-col>
				</van-row>
				<van-row type="flex" justify="space-between">
				  <van-col span="6">Healthy</van-col>
				  <van-col span="6">Alert</van-col>
				  <van-col span="6">A ile more</van-col>
				  <van-col span="6">Dangerous</van-col>
				</van-row>
				<span>The area of visceral fat was determined according to the CT of the abdomen.</span>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
	.van-row{
		text-align: center;
	}
	.bmi-h5-cotent-form{
		width: 100%;
		margin: 20px auto;
	}
	.van-row{
		font-size: 14px;
		font-weight: 200;
	}
	.van-col{
		padding: 5px 0;
		border: 1px solid #1f2f3c;
	}
	.bmi-h5-cotent-form span{
		font-size: 8px;
		margin: 15px 0;
		display: block;
		color: #82848A;
	}
	
</style>