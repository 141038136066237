<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Weight</div>
			<div class="bmi-h5-cotent-text">
				<div>
					Weight refers to body weight. In addition to thegrowth of bone, weight gain is also related togrowth in muscle and fat.
				</div>
				<div>Weight is one of the important indicators of aperson</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
</style>