<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Muscles mass</div>
			<div class="bmi-h5-cotent-text">
				<div>Muscles play an important role in maintainingbody temperature, movement, and heart beat,and creating energy. Muscles consist of skeletalmuscles, smooth muscles, and water.
				</div>
				<div>Muscle growth generally increases until the age of 20, and then gradually decreases after a plateauperiod. Body growth is an important period ofmuscle development, and so a balanced diet andregular exercise are very important.</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
</style>