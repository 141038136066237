























































































































































































































































































.productItem {
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.limited {
	// max-width: calc(100vw - 100px);
	// overflow: hidden;
	// text-overflow: ellipsis;
	// white-space: nowrap;
	// line-clamp: 2;
	height: 48px;
	line-height: 1.5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
}
.h5Context {
	max-width: 100%;
	overflow: hidden;
	img {
		max-width: 100%;
		height: auto;
		display: block;
	}
	p {
		margin: 0;
	}
}
