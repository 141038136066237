<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Body water percentage</div>
			<div class="bmi-h5-cotent-text">
				<div>Body water percentage refers to the waterpercentage of the weight in the body's
		
		composition. Body water includes blood, lymph,extracllular fluid, itraellularl fluid, etc. Bodymoisture has important functio
		
		transporting nutrients, recycling waste andkeeping body temperature.</div>
				<div>Body water percentage is generally higher in menthan women, tends to decrease with age, andpeople with a higher body fat percentage usuallyhave a lower water percentage. Daily body wapercentage varies depending on the amount ofwater ingested, the amount of physical activity,and the individual</div>
			</div>
			<div class="bmi-h5-cotent-form">
				<van-row>
				  <van-col span="24">Normal range of water rate</van-col>
				</van-row>
				<van-row type="flex" justify="space-between">
				  <van-col span="12">Female</van-col>
				  <van-col span="12">Male</van-col>
				</van-row>
				<van-row type="flex" justify="space-between">
				  <van-col span="12">About 45~60%</van-col>
				  <van-col span="12">About 50~65%</van-col>
				</van-row>
				
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
	.van-row{
		text-align: center;
	}
	.bmi-h5-cotent-form{
		width: 90%;
		margin: 20px auto;
	}
	.van-row{
		font-size: 14px;
		font-weight: 200;
	}
	.van-col{
		padding: 5px 0;
		border: 1px solid #1f2f3c;
	}
</style>