<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Body Age</div>
			<div class="bmi-h5-cotent-text">
				<div>The body age as the body's age tendency is basedon the basalI metabolic rate; for the same bodyweight, the body composition is different, andthe body age is also different; he muscle mass ishigh, and the person with the|
		
		rate is younger, the muscle mass is low, and theperson with the low basal metabolic rate is older.</div>
				<div>The basal metabolic rate, fat rate and muscle mass affect the body's age.</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
</style>