<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">My Earnings</span>
			 are generated by fans shopping in Rabbit Mall. All products under Rabbit Mall have a fixed percentage commission, which is provided by Rabbit Mall Profit Promotion.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">Earned</span>
			 is the conversion of an order on the road when a fan purchases a product in the Rabbit mall and confirms that there is no return or exchange within 30 days after receiving the product. The income generated by the order is the income that can be viewed in the wallet.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">To Receive</span>
			 is an order generated when a fan makes a purchase in the Rabbit Mall. Thirty days after the order is confirmed and received, the order on the road is converted into a received order or a failed order when the order is returned or cancelled.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">Failed</span>
			  is the conversion of an order on the road when a fan purchases a product in Rabbit mall and the order is returned or cancelled.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">Earnings Today</span>
			  is generated when fans make purchases in the Rabbit mall. Today’s new revenue does not represent actual wallet revenue. Only after the user confirms the receipt and 7 days no return or exchange will be converted into actual revenue.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">Total Amount</span>
			  is the income earned plus the income on the road.
		</div>
		<div style="width: 95%;margin: 30px auto;font-size: 16px;">
			<span class="bmi-h5-cotent-title">Total revenue obtained</span>
			   is the conversion of revenue from orders on the road when fans purchase goods in Rabbit Mall and confirm that there is no return or exchange within 30 days after receipt.
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 18px;
		font-weight: 600;
	}
</style>