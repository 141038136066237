<template>
	<div class="bmi-h5-cotent">
		<div style="width: 95%;margin: 0 auto;">
			<div class="bmi-h5-cotent-title">Protein</div>
			<div class="bmi-h5-cotent-text">
				<div>Protein consists of one or more long chainmolecules composed of amino acids. Bodyprotein content is the proportion of protein tobody weight.</div>
				<div>Protein is the material basis of all life, an
		
		important component of body cells, and is themain material used for tissue renovation and repair.</div>
			</div>
			<div class="bmi-h5-cotent-form">
				<van-row type="flex" justify="space-between">
				  <van-col span="8"><van-icon name="arrow-left" />16% </van-col>
				  <van-col span="8">16~20%</van-col>
				  <van-col span="8"><van-icon name="arrow" />20%</van-col>
				</van-row>
				<van-row type="flex" justify="space-between">
				  <van-col span="8">Malnutrition</van-col>
				  <van-col span="8">Normal range</van-col>
				  <van-col span="8">Overnutrition</van-col>
				</van-row>
				
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped="scoped">
	.bmi-h5-cotent{
		min-height: 100vh;
	}
	.bmi-h5-cotent-title{
		font-size: 20px;
		padding: 20px 0;
	}
	.bmi-h5-cotent-text{
		font-size: 16px;
		line-height: 25px;
	}
	.bmi-h5-cotent-text div{
		margin-bottom: 15px;
	}
	.van-row{
		text-align: center;
	}
	.bmi-h5-cotent-form{
		width: 90%;
		margin: 20px auto;
	}
	.van-row{
		font-size: 14px;
		font-weight: 200;
	}
	.van-col{
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #1f2f3c;
	}
</style>