.left {
  background-color: white;
  box-sizing: border-box;
  width: 25%;
}
.left .topcat {
  border-left: 3px solid transparent;
}
.left .active {
  background-color: rgba(247, 142, 30, 0.05);
  border-color: #f78e1e;
  color: #f78e1e;
}
.left ::v-deep .loadingImage {
  width: 32px;
  height: 32px;
}
.right {
  max-height: 100%;
  overflow: auto;
}
.right > img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.right .cr2 {
  background-color: white;
  border-radius: 8px;
}
.right .cr3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.right .cr3 img {
  width: 50%;
}
.right ::v-deep .van-image__img {
  min-height: 50px;
  width: 60%;
  height: auto;
  margin: auto;
}
.right ::v-deep .loadingImage {
  max-width: 100px;
  max-height: 100px;
  width: 18.6vw;
  height: 18.6vw;
}
