


























































.left {
	background-color: white;
	box-sizing: border-box;
	width: 25%;
	.topcat {
		border-left: 3px solid transparent;
	}
	.active {
		background-color: rgba(#f78e1e, 0.05);
		border-color: #f78e1e;
		color: #f78e1e;
	}
	::v-deep .loadingImage {
		width: 32px;
		height: 32px;
	}
}
.right {
	max-height: 100%;
	overflow: auto;
	> img {
		width: 100%;
		height: auto;
		border-radius: 10px;
	}
	.cr2 {
		background-color: white;
		border-radius: 8px;
	}
	.cr3 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		img {
			width: 50%;
		}
	}
	::v-deep .van-image__img {
		min-height: 50px;
		width: 60%;
		height: auto;
		margin: auto;
	}
	::v-deep .loadingImage {
		max-width: 100px;
		max-height: 100px;
		width: 18.6vw;
		height: 18.6vw;
	}
}
